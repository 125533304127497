import { useLoadScript } from '@react-google-maps/api'
import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import './App.css'
import ComingDays from './Components/ComingDays'
import Hours from './Components/Hours'
import MainCast from './Components/MainCast'
import MoreDetails from './Components/MoreDetails'
import Search from './Components/Search'
import Sidebar from './Components/Sidebar'
import { getByCoords, getWeatherData } from './utils/data'

function App() {
  const [theme, setTheme] = useState()
  const [coords, setCoords] = useState()
  const [unite, setUnite] = useState('metric')
  const [libraries] = useState(['places'])
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries
  })

  useEffect(() => {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setTheme('dark')
    } else {
      setTheme('light')
    }
  }, [])

  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [theme])

  const handleThemeSwitch = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark')
  }

  const { data: weatherData, isLoading } = useQuery(
    ['weather', coords],
    () => getWeatherData(coords),
    {
      retry: 1,
      cacheTime: 10,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false
    }
  )

  useEffect(() => {
    if (!coords) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          getByCoords({ lat: position.coords.latitude, lng: position.coords.longitude }).then(
            (res) => {
              setCoords(res)
            }
          )
        },
        (err) => {
          console.log(err)
        },
        { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
      )
    }
  }, [coords])

  if (!isLoaded || isLoading)
    return (
      <div className="h-screen p-5 text-4xl flex flex-col-reverse justify-center items-center dark:bg-gray-900 dark:text-gray-50">
        <h1>Loading...</h1>
        <img src="./assets/dark/01d.svg" alt="" className="w-1/3" />
      </div>
    )

  return (
    <div className="min-h-screen w-full grid place-items-center text-gray-900 bg-slate-50 dark:bg-gray-900 pb-5 md:pb-0 dark:text-white md:p-7">
      <div className="md:max-w-[80%] mx-auto  grid grid-cols-4 md:grid-cols-6 md:grid-row-6 ">
        <Sidebar
          handleThemeSwitch={handleThemeSwitch}
          unite={unite}
          setUnite={setUnite}
          theme={theme}
        />
        <div className="col-start-1 col-span-3 row-start-1 md:col-span-4 row-span-1 grid items-center px-2">
          <Search setCoords={setCoords} />
        </div>
        {weatherData && (
          <div className="max-w-[100vw] px-2 grid col-span-4 md:col-span-6 row-span-5  grid-cols-1 md:grid-cols-6 ">
            <div className="col-span-1 md:col-span-4">
              <MainCast unite={unite} coords={coords} main={weatherData.current} theme={theme} />
              <Hours unite={unite} hours={weatherData.hourly} theme={theme} />
              <MoreDetails unite={unite} main={weatherData.current} theme={theme} />
            </div>
            <div className="mt-4 md:mt-2 md:col-span-2 md:px-3">
              <ComingDays unite={unite} days={weatherData.daily} theme={theme} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default App
