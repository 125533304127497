export async function getWeatherData(coords) {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/weather?lat=${coords.lat}&lon=${coords.lng}`
  )
  const data = await res.json()
  return data
}

export async function getByCoords(coords) {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/city?lat=${coords.lat}&lng=${coords.lng}`
  )
  const data = await res.json()
  return data
}
