import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete'
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption
} from '@reach/combobox'

export default function Search({ setCoords }) {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions
  } = usePlacesAutocomplete({
    cache: 24 * 60 * 60,
    requestOptions: {},
    debounce: 500
  })

  const handleSelect = async (address) => {
    setValue(address, true)
    clearSuggestions()

    const results = await getGeocode({ address })
    const { lat, lng } = await getLatLng(results[0])
    setCoords({ lat, lng, address })
    setValue('', false)
  }

  return (
    <Combobox onSelect={handleSelect} className="">
      <div className="relative  grid items-center">
        <ComboboxInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          placeholder="Search for location"
          className="text-md bg-gray-100 dark:text-gray-50 py-3 px-4 rounded-xl overflow-hidden border-solid border-[1px] font-semibold dark:bg-gray-900 placeholder:text-gray-900 dark:placeholder:text-slate-400 outline-none w-full border-[#346ac3] focus:border-2"
        />
      </div>
      {status === 'OK' && (
        <ComboboxPopover className="text-md bg-gray-100 dark:text-gray-50 py-3 rounded-xl overflow-hidden  border-solid font-semibold dark:bg-gray-900 outline-none w-full border-[#346ac3] border-[1px] cursor-pointer mt-1">
          <ComboboxList className="divide-y-2 h-[150px] overflow-y-scroll ">
            {data.map(({ place_id, description }) => (
              <ComboboxOption
                key={place_id}
                value={description}
                className="p-2 dark:hover:bg-gray-600 hover:bg-gray-100 rounded-sm"
              />
            ))}
          </ComboboxList>
        </ComboboxPopover>
      )}
    </Combobox>
  )
}
