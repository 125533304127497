import React from 'react'
import { getUnite } from '../utils/getUnite'

function MoreDetails({ main, unite, theme }) {
  const imgSrc = theme === 'dark' ? `./assets/dark/` : `./assets/light/`
  return (
    <div className="font-bold px-3 py-1 bg-gray-100  dark:bg-gray-800 mt-4 rounded-xl border-[#346ac3] border-[1px]">
      <h1 className="uppercase text-gray-600 text-xs">air condition</h1>
      <div className="grid grid-cols-2">
        <div className="px-4">
          <p className="text-sm text-gray-500 flex items-center capitalize">
            <img src={`${imgSrc}celsius.svg`} alt="" className="w-10 " />
            feels like
          </p>
          <p className="text-2xl px-5">
            {getUnite(main.feels_like.toFixed(), unite)}
            <span>°</span>
          </p>
        </div>
        <div className="px-4">
          <p className="text-sm text-gray-500 flex items-center">
            <img src={`${imgSrc}windsock.svg`} alt="" className="w-10" />
            Wind
          </p>
          <p className="text-2xl px-5">
            {main.wind_speed.toFixed(1)}
            <span>Km/h</span>
          </p>
        </div>
        <div className="px-4">
          <p className="text-sm text-gray-500 flex items-center">
            <img src={`${imgSrc}humidity.svg`} alt="" className="w-10" />
            Humidity
          </p>
          <p className="text-2xl px-5">{main.humidity}</p>
        </div>
        <div className="px-4">
          <p className="text-sm text-gray-500 flex items-center">
            <img src={`${imgSrc}uv.svg`} alt="" className="w-10" />
            UV Index
          </p>
          <p className="text-2xl px-5">{main.uvi.toFixed()}</p>
        </div>
      </div>
    </div>
  )
}

export default MoreDetails
