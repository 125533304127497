import { BsFillSunFill, BsFillMoonFill } from 'react-icons/bs'
import { RiCelsiusFill, RiFahrenheitFill } from 'react-icons/ri'

function Sidebar({ handleThemeSwitch, unite, setUnite, theme }) {
  return (
    <div className="flex p-3 gap-2 col-start-4 md:gap-4 md:justify-center md:col-span-1 md:row-span-6">
      <button
        className="w-8 h-8 bg-gray-100 dark:bg-gray-700 rounded-full ring-2 ring-offset-gray-400 grid place-items-center"
        onClick={handleThemeSwitch}
      >
        {theme === 'dark' ? <BsFillSunFill /> : <BsFillMoonFill />}
      </button>
      <button
        className="w-8 h-8 bg-gray-100 dark:bg-gray-700 rounded-full ring-2 ring-offset-gray-400  grid place-items-center"
        onClick={() => setUnite(unite === 'metric' ? 'imperial' : 'metric')}
      >
        {unite === 'metric' ? <RiCelsiusFill /> : <RiFahrenheitFill />}
      </button>
    </div>
  )
}

export default Sidebar
