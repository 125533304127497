import React from 'react'
import { getUnite } from '../utils/getUnite'

function MainCast({ coords, main, unite, theme }) {
  const imgSrc =
    theme === 'dark'
      ? `./assets/dark/${main.weather[0].icon}.svg`
      : `./assets/light/${main.weather[0].icon}.svg`
  return (
    <div className="dark:text-gray-600 md:px-3 grid py-1 md:grid-cols-3 place-items-center md:place-items-start rounded-xl mb-2">
      <div className="col-span-1 ">
        <p className="dark:text-gray-200 text-4xl font-normal ">{coords?.address?.split(',')[0]}</p>
      </div>

      <div className="md:col-span-2 grid place-items-center md:place-self-end place-self-center">
        <img src={imgSrc} alt="" className="w-[200px] object-cover " />
      </div>
      <div className="">
        <p className="text-6xl md:-mt-11 dark:text-gray-200  font-semibold ">
          {getUnite(main.temp.toFixed(), unite)}
          <sup className="font-thin">°</sup>
        </p>
      </div>
    </div>
  )
}

export default MainCast
