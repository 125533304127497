import { getUnite } from '../utils/getUnite'

const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

function ComingDays({ days, unite, theme }) {
  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)

  return (
    <div className="dark:text-gray-400  p-5 bg-gray-100 dark:bg-gray-800 rounded-xl border-[#346ac3] border-[1px]">
      <h1 className=" uppercase">7-day forcast</h1>
      <div className="divide-y-[1px] divide-gray-700 dark:divide-gray-100">
        {days.map((day) => {
          const imgSrc =
            theme === 'dark'
              ? `./assets/dark/${day.weather[0].icon}.svg`
              : `./assets/light/${day.weather[0].icon}.svg`
          return (
            <div key={day.dt} className="grid grid-cols-6 items-center py-3.5 text-xl md:text-sm">
              <p className="col-span-2 font-semibold ">
                {new Date(day.dt * 1000).getDate() === today.getDate()
                  ? 'Today'
                  : new Date(day.dt * 1000).getDate() === tomorrow.getDate()
                  ? 'Tomorrow'
                  : weekDays[new Date(day.dt * 1000).getDay()]}
              </p>
              <div className="col-span-3 flex gap-2 items-center text-xl md:text-sm ">
                <img src={imgSrc} alt="" className="w-8 h-8" />
                <span>{day.weather[0].description}</span>
              </div>
              <div className="ml-auto ">
                <span className="dark:text-gray-100  font-bold text-md">
                  {getUnite(day.temp.max.toFixed(), unite)}
                </span>
                <span>/</span>
                <span className="text-gray-700 font-semibold dark:text-gray-500">
                  {getUnite(day.temp.min.toFixed(), unite)}
                </span>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ComingDays
