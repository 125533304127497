import React from 'react'
import { getUnite } from '../utils/getUnite'

function Hours({ hours, unite, theme }) {
  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)
  const todayHours = hours.filter((h, i) => {
    return new Date(h.dt * 1000).getDate() === today.getDate()
  })

  const remainingHours =
    todayHours.length > 7
      ? todayHours
      : hours.filter((h, i) => {
          return i < 7
        })
  return (
    <div className="w-full dark:text-gray-500 font-bold  pt-1 dark:bg-gray-800 bg-gray-100 rounded-xl overflow-hidden border-[#346ac3] border-[1px]">
      <h1 className=" uppercase text-xs text-gray-600 px-3">today's forcast</h1>
      <div className=" flex gap-1 overflow-x-scroll scroll p-1 divide-x divide-gray-700 dark:divide-gray-100">
        {remainingHours.map((hour) => {
          const imgSrc =
            theme === 'dark'
              ? `./assets/dark/${hour.weather[0].icon}.svg`
              : `./assets/light/${hour.weather[0].icon}.svg`
          return (
            <div key={hour.dt} className="min-w-[95px] flex flex-col items-center">
              <div className="time">{`${new Date(hour.dt * 1000).getHours()}:00 `}</div>
              <div className="icon ">
                <img src={imgSrc} alt="" className="w-14 h-14" />
              </div>
              <div className="dark:text-gray-200 text-xl">
                {getUnite(hour.temp.toFixed(), unite)}
                <sup>°</sup>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Hours
